/* CSS CUSTOM VARS */
:root {

    --content-width: 1250px;

    --primary-color: #000;
    --secondary-color: #863c48;

    --font-size-small: .75rem;
    --font-size-base: clamp(1rem, 2vw, 1.125rem);
    --font-size-large: 1.5rem;
    --font-size-xlarge: 2rem;

    --spacing: 1em;
    --spacing-2: 1.5em;
    --spacing-3: 2em;
    --spacing-4: 3em;

    --serif: "Times New Roman", Times, Georgia, serif;
    --sans-serif: "neue-haas-grotesk-display", Verdana, Helvetica, Arial, sans-serif;
    --display-font: "freight-big-pro", Verdana, Helvetica, Arial, sans-serif;

    --mini: 320px;
    --small: 800px;
    --medium: 960px;
    --large: 1280px;
    --xlarge: 1400px;
    --xxlarge: 1600px;

}

/* CSS IMPORTS */
@import "sanitize.css";
@import "sanitize.css/assets.css";
@import "sanitize.css/forms.css";
@import "sanitize.css/reduce-motion.css";
@import "sanitize.css/typography.css";
@import "https://use.typekit.net/qoh0qld.css";

/* CSS */
body {
    background: #fff;
    color: var(--primary-color);
    font-size: var(--font-size-base);
    font-family: var(--sans-serif);
}

.wrap {
    padding: 0 var(--spacing);
    margin-inline: auto;
    max-width: var(--content-width);
}

.header__logo {
    padding: 4em 0 1em;
}

img.logo {
    height: 75px;
}

h1 {
    font-family: var(--display-font);
    font-size: 4em;
}

h2 {
    font-size: 1.75em;
    line-height: 1.2;
    margin: 1.2em 0 0;
    font-weight: 400;
    color: #873c48;
}

/* CSS - MAIN */
main {
    border-bottom: 1px solid #ddd;
}

main h2,
main p:not(.cta p) {
    max-width: 900px;
    margin: 1.5em auto 1em;
}

.container__inner {
    display: flex;
    padding: 3em 0;
}

.container__inner:first-of-type {
    border-bottom: 1px solid #ddd;
}

.container__content {
    width: 50%;
    color: #fff;
    padding: 1em;
}

.container__content__inner {
    padding: 4em;
}

.container__inner:first-of-type .container__content {
    background: linear-gradient(0deg,#fff,#fff 38px,#493e40 0,#493e40);
}

.container__content__inner .container__content__title::after {
    display: block;
    content: "";
    height: 2px;
    background: linear-gradient(90deg,#c64a5d,#c64a5d 38px,transparent 0,transparent 120px,#fff 0,#fff);
    transform: translateX(-40px);
}

.container__inner:nth-of-type(2) .container__content__inner .container__content__title::after {
    background: linear-gradient(90deg,#80f363,#80f363 38px,transparent 0,transparent 120px,#fff 0,#fff);
}

.container__inner:nth-of-type(2) .container__content {
    background: linear-gradient(0deg,#fff,#fff 38px,#873c48 0,#873c48);
}

.container__content h1 {
    margin: 0;
}

.container__image {
    width: 50%;
}

.image__audience {
    mix-blend-mode: luminosity;
}

.strong {
    font-weight: bold;
}

.cta {
    background-color: #463E41;
    color: #fff;
    padding: 1.25em 4em 2.5em;
    font-size: 1.5rem;
    margin: 4.5em 0;
}

.cta p:last-of-type {
    padding-bottom: 1em;
}

a.button {
    background-color: #fff;
    color: black;
    font-size: 1.25rem;
    text-decoration: none;
    text-transform: uppercase;
    padding: 1rem 2rem;
    font-weight: bold;
}


/* CSS - FOOTER */
footer {
    padding: 4em var(--spacing) 8em;
}

.footer__wrapper {
    display: flex;
}

.footer__inner {
    width: 50%;
    padding: 0 5em;
}

.footer__inner:first-of-type {
    /* border: 1px solid red; */
    align-content: center;
}

.footer__inner span {
    font-size: 0.75em;
}

.footer__inner:last-of-type .footer__logo {
    padding-bottom: 1em;
}

.footer__inner:first-of-type .footer__logo {
    text-align: right;
}

[itemprop=name]:first-of-type {
    font-weight: bold;
    border-top: 1px solid #eee;
    padding-top: 1em;
    max-width: 335px;
}


[itemprop=name],
[itemprop=streetAddress] {
    display: block;
}



/* MEDIA QUERIES */
@media (max-width: 800px)  {
    .cta {
        padding: 1.25em 1em 2.5em;
    }

    a.button {
        font-size: 1rem;
        display: block;
        text-align: center;
    }

    .footer__wrapper {
        flex-direction: column;
        align-items: center;
        gap: 3em;
    }

    .footer__inner {
        padding: 0;
    }
}

@media (max-width: 960px) {
    h1 {
        font-size: 3em;
    }

    .container__inner {
        flex-direction: column;
    }

    .container__content,
    .container__image,
    .image__dorothy {
        width: 100%;
    }

    .container__inner:first-of-type .container__content {
        background: #493e40;
    }

    .container__inner:nth-of-type(2) .container__content {
        background: #873c48;
    }
}

@media (max-width: 1280px) {
    .container__content__inner {
        padding: 1em;
    }

    .container__inner .container__content__title::after {
        transform: none;
    }
}